import Slider, { mode1, mode2, mode3 } from './Slider';
import Rail from './Rail';
import Ticks from './Ticks';
import Tracks from './Tracks';
import Handles from './Handles';
Slider.Rail = Rail;
Slider.Ticks = Ticks;
Slider.Tracks = Tracks;
Slider.Handles = Handles;
export default Slider;
export { Slider, Rail, Ticks, Tracks, Handles, mode1, mode2, mode3 };